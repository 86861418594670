<template>
    <div class="p-change-log">
        <div class="version">
            <div class="name">
                0.5 - Beta （公测版）<span class="date">(2021-07-15)</span>
            </div>
            <div class="detail">
                <ul>
                    <li>优化项目列表速度。(2021-07-15)</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.5 - Beta （公测版）<span class="date">(2021-07-09)</span>
            </div>
            <div class="detail">
                <ul>
                    <li>修改页脚排版。(2021-07-09)</li>
                    <li>
                        修改合同号规则，支持非VIP合同的子合同号。(2021-07-09)
                    </li>
                    <li>项目列表分页。(2021-07-09)</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.4 - Beta （公测版）<span class="date">(2021-07-01)</span>
            </div>
            <div class="detail">
                <ul>
                    <li>修复项目详情页备注无法重复提交的 bug 。(2021-07-01)</li>
                    <li>合同增加签订日期和结束日期字段。(2021-07-01)</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.3 - Beta （公测版）<span class="date">(2021-07-01)</span>
            </div>
            <div class="detail">
                <ul>
                    <li>增加项目导入功能1。(2021-07-01)</li>
                    <li>把左边菜单改成链接。(2021-06-30)</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.2 - Beta （公测版）<span class="date">2021-06-20</span>
            </div>
            <div class="detail">
                <ul>
                    <li>增加获取监控项目列表的接口。(2021-06-20)</li>
                    <li>增加客户端 IP 判断，增强安全性。(2021-06-15)</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.1 - Beta （公测版）<span class="date">2021-06-10</span>
            </div>
            <div class="detail">
                <ul>
                    <li>增加"项目状态"字段</li>
                </ul>
            </div>
        </div>

        <div class="version">
            <div class="name">
                0.4.0 - Beta （公测版）<span class="date">2021-06-09</span>
            </div>
            <div class="detail">
                <ul>
                    <li>用户可以自己修改密码</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.p-change-log {
    padding-top: 10px;

    .version {
        font-size: 16px;
        padding: 0 0 40px 10px;
    }

    .name {
        font-weight: bold;
    }

    .date {
        font-size: 14px;
        color: #999;
    }

    .detail {
        padding-top: 10px;
        padding-left: 20px;

        li {
            list-style: disc outside none;
        }
    }
}
</style>

<script>
export default {
    components: {},
    data() {
        return {
            ready: false,
            isLoading: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {},
    filters: {},
};
</script>
